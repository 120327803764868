import type { CreateNFormItemConfig } from '@/types/form'

export const createNFormItemConfig = (customAttrs: Record<string, unknown>): CreateNFormItemConfig => {
  return (state: any) => ({
    model: 'value',
    props: {
      validationStatus: state.errors[0] ? 'error' : undefined,
      feedback: state.errors[0],
      debounce: 500,
      ...customAttrs,
    },
  })
}
